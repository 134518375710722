import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';
import VanillaTilt from 'vanilla-tilt';


import HeaderSection from './components/common/HeaderSection'
import FooterSection from './components/common/FooterSection'
import ScrollToTop from './components/ScrollToTop';



function AppOutlet() {
    const location = useLocation();
    useEffect(() => {
        /*================================================================= 
            Navbar fixed top
        ==================================================================*/
        function scroll() {
            if ($(window).scrollTop() >= 300) {
                $('.site-header nav').addClass('fixed-top');
            } else {
                $('.site-header nav').removeClass('fixed-top');
            }
        }
        document.onscroll = scroll;
        /*================================================================= 
            Initialize Vanilla-Tilt
        ==================================================================*/
        VanillaTilt.init(document.querySelectorAll('[data-tilt]'), {
            max: 10,
            speed: 2000
        });

    }, [location]);
    return (
        <>
            <HeaderSection />
            <ScrollToTop />
            <Outlet />
            <FooterSection />
        </>
    )
}

export default AppOutlet