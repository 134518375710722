import React from 'react'

function ServicesFeatuers() {
    return (
        <>
            <section className="features-section section-block">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 image-block">
                            <div className="image-wrapper">
                                <img className="img-fluid feature-photo" src="assets/image/feature-photo.png" alt="features photo" data-tilt data-tilt-max="10" />
                            </div>
                        </div>
                        <div className="col-xl-6 content-block">
                            <h2 className="block-title"><span>Why work with me</span>Best result with top user experience</h2>
                            <p>
                                Achieving optimal results through user experience involves intuitive design, responsiveness, personalization, accessibility, and continuous improvement for lasting satisfaction.
                            </p>
                            <ul className="features-list">
                                <li className="d-flex align-items-start">
                                    <div className="icon-block">
                                        <div className="icon-box"><i className="bi bi-chevron-right"></i></div>
                                    </div>
                                    <div className="content-wrapper">
                                        <h4>Custom Solution</h4>
                                        <p>
                                            Tailored solutions designed to meet unique needs, ensuring optimal functionality and enhanced user satisfaction.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start">
                                    <div className="icon-block">
                                        <div className="icon-box"><i className="bi bi-chevron-right"></i></div>
                                    </div>
                                    <div className="content-wrapper">
                                        <h4>Regular Updates</h4>
                                        <p>
                                            Regular updates provide users with new features, improvements, and security enhancements, ensuring a reliable and up-to-date experience.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start">
                                    <div className="icon-block">
                                        <div className="icon-box"><i className="bi bi-chevron-right"></i></div>
                                    </div>
                                    <div className="content-wrapper">
                                        <h4>Lifetime Support</h4>
                                        <p>
                                            Lifetime support ensures ongoing assistance and resources for users, enhancing satisfaction and fostering long-term relationships.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ServicesFeatuers