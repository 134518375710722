import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import AppOutlet from './AppOutlet'
import TopTitle from './components/TopTitle';

import BgSection from './components/common/BgSection'
import HomeSection from './components/Home/HomeSection'
import AboutUsSection from './components/AboutUs/AboutUsSection'
import SkillSection from './components/Skills/SkillSection'
import PortfolioSection from './components/Portfolio/PortfolioSection';
import ServicesSection from './components/Services/ServicesSection';
import ServicesFeatuers from './components/Services/ServicesFeatuers';
import TestimonialSection from './components/Testimonial/TestimonialSection';
import ContactUsSection from './components/ContactUs/ContactUsSection';


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<AppOutlet />}>
            <Route path='/' element={
                <>
                    <TopTitle title="" />
                    <HomeSection />
                    <AboutUsSection />
                    <SkillSection />
                    <PortfolioSection />
                    <ServicesSection />
                    <ServicesFeatuers />
                    <TestimonialSection />
                </>
            } />
            <Route path='/home' element={
                <>
                    <TopTitle title="Home - " />
                    <HomeSection />
                    <AboutUsSection />
                    <SkillSection />
                    <PortfolioSection />
                    <ServicesSection />
                    <ServicesFeatuers />
                    <TestimonialSection />
                </>
            } />
            <Route path='/about-us' element={
                <>
                    <TopTitle title="About Us - " />
                    <BgSection title="About Us" />
                    <AboutUsSection />
                    <SkillSection />
                    <TestimonialSection />
                </>
            } />

            <Route path='/portfolio' element={
                <>
                    <TopTitle title="Portfolio - " />
                    <BgSection title="Portfolio" />
                    <PortfolioSection />
                </>
            } />

            <Route path='/services' element={
                <>
                    <TopTitle title="Services - " />
                    <BgSection title="Services" />
                    <ServicesSection />
                    <ServicesFeatuers />
                </>
            } />

            <Route path='/contact-us' element={
                <>
                    <TopTitle title="Contact Us - " />
                    <BgSection title="Contact Us" />
                    <ContactUsSection />
                </>
            } />
        </Route>
    )
)

function App() {
    return (
        <>
            <RouterProvider router={router} />
        </>
    )
}

export default App