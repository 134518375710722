import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay } from 'swiper/modules';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

function TestimonialSection() {
    return (
        <section className="testimonial-section section-block">
            <div className="container">
                <div className="section-title dark">
                    <h2>What Clients Say About Our Services</h2>
                    <p className="lead" style={{ color: "white" }}>
                        Clients praise our digital services for effectiveness, tailored solutions, exceptional support, and a seamless user experience that drives success.
                    </p>
                </div>
                <div className="testimonial-carousel swiper">
                    <Swiper
                        loop={true}
                        autoplay={{
                            delay: 4000, // Adjust delay as needed
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        modules={[Pagination, Autoplay]}
                        slidesPerView={3}
                        spaceBetween={30}
                        breakpoints={{
                            1024: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            0: {
                                slidesPerView: 1,
                            }
                        }}
                        className="swiper-wrapper"
                    >
                        <SwiperSlide>
                            <div className="col-md-4 testimonial-item swiper-slide">
                                <div className="item-wrapper">
                                    <div className="quote-icon">
                                        <FontAwesomeIcon icon={faQuoteLeft} style={{ color: "white", fontSize: 30 }} />
                                    </div>
                                    <p>
                                        Super fast - great communication - delivered exactly what I was looking for. The code is really solid, well written and well organized. We had a very tight deadline, almost an unreasonable requirement from us, and he met it without issue. I will absolutely be sending addition projects. Best experience
                                    </p>
                                    <div className="quote-author d-flex align-items-center justify-content-center">
                                        <div className="image-block">
                                            <img className="img-fluid" src="assets/image/testimonial/female-avatar.png" alt="testimonial author" />
                                        </div>
                                        <h4>arniykelly<span>United States</span></h4>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="col-md-4 testimonial-item swiper-slide">
                                <div className="item-wrapper">
                                    <div className="quote-icon">
                                        <FontAwesomeIcon icon={faQuoteLeft} style={{ color: "white", fontSize: 30 }} />
                                    </div>
                                    <p>
                                        I had an excellent experience working with Muhammad Safdar on Fiverr.
                                        Their diligence and commitment to my project were truly remarkable.
                                        I shared my idea with them, and they skillfully transformed it into a reality.
                                        I wholeheartedly recommend this seller to anyone looking to get their project done seamlessly and hassle-free.
                                    </p>
                                    <div className="quote-author d-flex align-items-center justify-content-center">
                                        <div className="image-block">
                                            <img className="img-fluid" src="assets/image/testimonial/male-avatar.png" alt="testimonial author" />
                                        </div>
                                        <h4>Manoj_981<span>India</span></h4>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="col-md-4 testimonial-item swiper-slide">
                                <div className="item-wrapper">
                                    <div className="quote-icon">
                                        <FontAwesomeIcon icon={faQuoteLeft} style={{ color: "white", fontSize: 30 }} />
                                    </div>
                                    <p>
                                        Muhammad Safdar is an exceptional, delivering high-quality solutions with professionalism,
                                        attention to detail, and excellent communication skills. Highly recommended for any project.
                                    </p>
                                    <div className="quote-author d-flex align-items-center justify-content-center">
                                        <div className="image-block">
                                            <img className="img-fluid" src="assets/image/testimonial/female-avatar.png" alt="testimonial author" />
                                        </div>
                                        <h4>Mahnoor Shaikhz<span>Pakistan</span></h4>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="col-md-4 testimonial-item swiper-slide">
                                <div className="item-wrapper">
                                    <div className="quote-icon">
                                        <FontAwesomeIcon icon={faQuoteLeft} style={{ color: "white", fontSize: 30 }} />
                                    </div>
                                    <p>
                                        I had an exceptional experience with Star Works!
                                        Their meticulous attention to detail and professionalism guaranteed a flawless software delivery,
                                        which is uncommon these days. Collaborating with them was effortless due to their prompt responsiveness and proactive communication.
                                    </p>
                                    <div className="quote-author d-flex align-items-center justify-content-center">
                                        <div className="image-block">
                                            <img className="img-fluid" src="assets/image/testimonial/male-avatar.png" alt="testimonial author" />
                                        </div>
                                        <h4>tom_downing<span>United Kingdom</span></h4>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default TestimonialSection;
