import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Typed from 'typed.js';

function HomeSection() {
    const typedElement = useRef(null);
    useEffect(() => {
        const options = {
            strings: [
                "Full Stack Development",
                "Business Applications Development",
                "CMS Development",
                "Mobile App Development",
                "Website Development",
            ],
            typeSpeed: 80,
            backSpeed: 100,
            loop: true,
        };
        const typed = new Typed(typedElement.current, options);
        return () => {
            typed.destroy();
        };
    }, []);


    return (
        <>
            <section className="hero-area" id="hero-area">
                <div className="container">
                    <div className="hero-content d-flex justify-content-center">
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-xl-6 order-xl-last image-block">
                                <div className="image-wrapper" data-tilt data-tilt-max="10">
                                    <ul className="hero-facts">
                                        <li>
                                            <p>99<span>+</span></p>
                                            <h4>Total <br />Projects</h4>
                                        </li>
                                        <li>
                                            <p>69<span>+</span></p>
                                            <h4>Worldwide <br />Clients</h4>
                                        </li>
                                    </ul>
                                    <img className="img-fluid" src="assets/image/hero_main_image.png" alt="hero main image" />
                                </div>
                            </div>

                            <div className="col-xl-6 order-xl-first content-block">
                                <h1 className="hero-head"><small>Hello, I'm</small>Muhammad <br /><strong>Safdar</strong></h1>
                                <p>
                                    I'm a passionate <strong ref={typedElement}></strong>
                                </p>
                                <div className="link-group">
                                    <a className="btn-main" href="/contact-us">Contact Me</a>
                                    <ul className="hero-social list-inline">
                                        <li className="list-inline-item"><Link to="https://www.linkedin.com/in/themuhammadsafdar/" target='blank'><FontAwesomeIcon icon={faLinkedin} /></Link></li>
                                        <li className="list-inline-item"><Link to="https://www.instagram.com/muh.safdar/" target='blank'><FontAwesomeIcon icon={faInstagram} /></Link></li>
                                        <li className="list-inline-item"><Link to="https://www.facebook.com/muh.safdar5" target='blank'><FontAwesomeIcon icon={faFacebook} /></Link></li>
                                        <li className="list-inline-item"><Link to="https://x.com/muh_safdar5" target='blank'><FontAwesomeIcon icon={faTwitter} /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSection