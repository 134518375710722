import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaravel, faHtml5, faCss3Alt, faBootstrap, faJs, faPhp, faReact, faGithub, faNode } from '@fortawesome/free-brands-svg-icons';
import { faDatabase, faJ, faWind } from '@fortawesome/free-solid-svg-icons';

function SkillSection() {
    return (
        <>
            <section className="section-block">
                <div className="interests container">
                    <div className="section-title">
                        <h2>Discover my Expertise and Capabilities</h2>
                        <p className="lead">
                            Explore my skills and knowledge to find solutions tailored to your needs.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faHtml5} style={{ color: "#E5532D" }} className="icons-font" />
                                <h3>HTML</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faCss3Alt} style={{ color: "#2D53E5" }} className="icons-font" />
                                <h3>CSS</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faBootstrap} style={{ color: "#8C18FD" }} className="icons-font" />
                                <h3>Bootstrap</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faWind} style={{ color: "#8C18FD" }} className="icons-font" />
                                <h3>Tailwind CSS</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faJs} style={{ color: "#EBCC37" }} className="icons-font" />
                                <h3>JavaScript</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faJ} style={{ color: "#0F77B6" }} className="icons-font" />
                                <h3>JQuery</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faPhp} style={{ color: "#7B7FB5" }} className="icons-font" />
                                <h3>PHP</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faDatabase} style={{ color: "#AD6408" }} className="icons-font" />
                                <h3>MySQLi</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faLaravel} style={{ color: "#FF2118" }} className="icons-font" />
                                <h3>Laravel</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faReact} style={{ color: "#08D9FF" }} className="icons-font" />
                                <h3>React JS / Next JS</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faReact} style={{ color: "#08D9FF" }} className="icons-font" />
                                <h3>React Native</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faDatabase} style={{ color: "#55AD47" }} className="icons-font" />
                                <h3>Mongo DB</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faNode} style={{ color: "#84BF08" }} className="icons-font" />
                                <h3>Node JS</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon icon={faGithub} style={{ color: "#16181F" }} className="icons-font" />
                                <h3>GitHub</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SkillSection;
