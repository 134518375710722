import React, { useEffect, useRef } from 'react';
import PortfolioItem from './PortfolioItem';
import { useLocation } from 'react-router-dom';


function PortfolioSection() {
    const location = useLocation();
    const isPortfolioPage = location.pathname === '/portfolio';
    return (
        <section className="portfolio-section section-block" id="portfolio-section">
            <div className="container">
                <div className="section-title">
                    <h2>Explore my Portfolio of Projects</h2>
                    <p className="lead">
                        Browse my diverse projects showcasing creativity, expertise, and innovative solutions.
                    </p>
                </div>
                <div className="row grid" >

                    <PortfolioItem
                        imageName="salon-care-portfolio.png"
                        title="Salon Care & Beauty Parlour Management System"
                        description="A Salon Care & Beauty Parlour Management System streamlines appointments, customer records, staff scheduling, attendance, reports, accounts, expense, profit and loss and billing, enhancing efficiency and customer satisfaction."
                    />

                    <PortfolioItem
                        imageName="payroll-wsl-software.png"
                        title="Payroll Management System"
                        description="A Payroll Management System automates employee salary calculations, tax deductions, Ledger tracking, and ensures Employee Ledger and accurate payment, simplifying payroll processes."
                    />

                    <PortfolioItem
                        imageName="cafe-creame-software.png"
                        title="Cafe Creame (Point of Sale)"
                        description="Cafe Creame's Point of Sale system manages orders, payments, inventory, add categories, tables, products, daily and monthly reports, and customer data, ensuring seamless cafe operations and enhanced customer service."
                    />

                    <PortfolioItem
                        imageName="redesign-presentation-website.png"
                        title="Redesign Presentation Website"
                        description="Redesign Presentation is portfolio which have multi page website like About us, Serivces, Portfolio, Contact us, Blog page,and also company portfolio"
                    />

                    <PortfolioItem
                        imageName="solo-byte-tech-website.png"
                        title="SoloByte Tech Website"
                        description="SoloByte Tech is company portfolio which have multi page website like About Us, Serivces, Portfolio, Contact us, Blog page with animation."
                    />

                    <PortfolioItem
                        imageName="movie-app-website.png"
                        title="The Movie Tracker Website"
                        description="The Movie Tracker Website allows users to discover, track, and rate movies, providing personalized recommendations and a comprehensive watchlist management."
                    />


                    {isPortfolioPage && (
                        <>
                            {/* <PortfolioItem
                                imageName="portfolio-item-04.jpg"
                                title="Website design for Rainy Design"
                                description="this is testing"
                            />

                            <PortfolioItem
                                imageName="portfolio-item-05.jpg"
                                title="Website design for Rainy Design"
                                description="this is testing"
                            />

                            <PortfolioItem
                                imageName="portfolio-item-06.jpg"
                                title="Website design for Rainy Design"
                                description="this is testing"
                            /> */}
                        </>
                    )}

                </div>
            </div>
        </section>
    );
}

export default PortfolioSection;
