import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function ContactUsSection() {
    return (
        <>
            <section className="contact-section section-block" id="contact-section">
                <div className="container">
                    <div className="section-title">
                        <h2>Get in Touch</h2>
                        <p className="lead">
                            Get in touch for questions or collaborations.
                        </p>
                    </div>
                    <div className='row'>
                        <div className="col-lg-4 d-flex" data-aos="fade-down" data-aos-duration="1000">
                            <div className="icon-box"><FontAwesomeIcon icon={faLocationDot} /></div>
                            <div className="content-wrapper">
                                <h4>Address</h4>
                                <address>Lohore, Punjab, Pakistan</address>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">
                            <div className="icon-box"><FontAwesomeIcon icon={faPhone} /></div>
                            <div className="content-wrapper">
                                <h4>Phone</h4>
                                <a href="#">+92 316 6539 256</a>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">
                            <div className="icon-box"><FontAwesomeIcon icon={faEnvelope} /></div>
                            <div className="content-wrapper">
                                <h4>Email</h4>
                                <a href="#">muh.safdarmalik@gmail.com</a>
                            </div>
                        </div>
                        {/* form */}

                        <div className="col-lg-12 form-block mt-5">
                            <form className="g-3" method="POST" action="">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="input-group mb-3">
                                            <label htmlFor="inputName" className="form-label visually-hidden">Name</label>
                                            <input type="text" className="form-control" id="inputName" placeholder="Name*" name="inputName" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-3">
                                            <label htmlFor="inputEmail" className="form-label visually-hidden">Email</label>
                                            <input type="email" className="form-control" id="inputEmail" name="inputEmail" placeholder="Email*" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-group mb-3">
                                            <label htmlFor="inputName" className="form-label visually-hidden">Subject</label>
                                            <input type="text" className="form-control" id="inputName" placeholder="Subject*" name="inputName" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="inputMessage" className="form-label visually-hidden">Message</label>
                                        <textarea className="form-control mb-3" id="inputMessage" name="inputMessage" placeholder="Your message here*" required></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <button type="submit" className="btn-main" name="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUsSection