import React from 'react'
import { Link } from 'react-router-dom'

function FooterSection() {
    return (
        <>
            <footer className="site-footer" id="site-footer">
                <div className="container">
                    <div className="back-to-top"><a href="#"><i className="bi bi-box-arrow-up"></i></a></div>
                    <div className="row footer-bottom">
                        <div className="col-md-12 text-center">
                            <p>All rights reserved &copy; 2024 <strong>Muhammad Safdar</strong></p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default FooterSection