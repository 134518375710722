import React from 'react'

function PortfolioItem(props) {
    return (
        <>
            <div className="col-lg-4 col-md-6 grid-item">
                <div className="box">
                    <img src={`assets/image/portfolio/${props.imageName}`} alt="portfolio image" />
                    <div className="box-content">
                        <h3 className="title">{props.title}</h3>
                        <p className="category">{props.description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioItem