import React from 'react'

function ServicesItems(props) {
    return (
        <>
            <div className="col-xl-4 col-lg-6">
                <div className="item-wrapper">
                    <div className="icon-box"><i className={props.titleIcon}></i></div>
                    <div className="content-wrapper">
                        <h3>{props.title}</h3>
                        <p>
                            {props.description}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesItems