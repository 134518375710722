import React from 'react'
import { Link } from 'react-router-dom'

function BgSection(props) {
    return (
        <>
            <div className="container-fluid py-5 bg-header">
                <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                        <h1 className="display-4 text-white">{props.title}</h1>
                        <Link to="/home" className="h5 text-white">Home</Link>
                        <i className="bi bi-skip-forward-fill text-white px-2"></i>
                        <Link to="" className="h5 text-white">{props.title}</Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BgSection