import React from 'react';
import { useLocation } from 'react-router-dom';
import ServicesItems from './ServicesItems';

function ServicesSection() {
    const location = useLocation();
    const isServicesPage = location.pathname === '/services';

    return (
        <>
            <section className="intro-section section-block">
                <div className="container">
                    <div className="section-title">
                        <h2>Discover the Services I Offer</h2>
                        <p className="lead">
                            Uncover a range of services designed to meet your unique needs and goals.
                        </p>
                    </div>
                    <div className="row">
                        <ServicesItems
                            titleIcon="bi bi-phone"
                            title="Mobile App Development"
                            description="Unlock innovative and user-friendly mobile app solutions tailored to elevate your business, driving engagement and growth across iOS and Android platforms." />
                        <ServicesItems
                            titleIcon="bi bi-display"
                            title="Website Development"
                            description="Transform your digital presence with custom website development that combines stunning design, seamless functionality, and optimized performance to engage and convert visitors." />
                        <ServicesItems
                            titleIcon="bi bi-optical-audio"
                            title="AI Based Solution"
                            description="Leverage cutting-edge AI solutions to automate processes, enhance decision-making, and drive intelligent insights that propel your business forward." />

                        {isServicesPage && (
                            <>
                                <ServicesItems
                                    titleIcon="bi bi-cast"
                                    title="Business Application Development"
                                    description="Develop powerful, custom business applications that streamline operations, boost productivity, and adapt to your evolving enterprise needs." />
                                <ServicesItems
                                    titleIcon="bi bi-signpost-2-fill"
                                    title="API Development & Integration"
                                    description="Create robust APIs and seamlessly integrate them to connect your systems, enhance functionality, and enable smooth data exchange across platforms." />
                                <ServicesItems
                                    titleIcon="bi bi-dribbble"
                                    title="Custom Management Software"
                                    description="Design tailored management software that simplifies complex workflows, enhances efficiency, and scales with your business needs." />
                                <ServicesItems
                                    titleIcon="bi bi-shop"
                                    title="E-commerce Development"
                                    description="Build a dynamic e-commerce platform that offers a seamless shopping experience, driving sales and customer loyalty with cutting-edge features and secure transactions." />
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesSection;
