import React from 'react'


function AboutUsSection() {
    return (
        <>
            <section className="about-section section-block">
                <div className="container">
                    <div className="section-title">
                        <h2>About Me</h2>
                        <p className="lead">
                            Building bridges between code and creativity, I transform ideas into dynamic applications, where every layer counts.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xl-5 image-block">
                            <div className="img-wrapper about-img-wrap" data-tilt data-tilt-max="10">
                                <img className="about-img-1 img-fluid" src="assets/image/about-image-1.png" alt="about image" />
                                <img className="about-img-2 img-fluid" src="assets/image/about-image-2.png" alt="about image 2" />
                            </div>
                        </div>
                        <div className="col-xl-7 content-block">
                            <h2>Full Stack Developer: Expert in crafting Complete Solutions</h2>
                            <div className="row">
                                <div className='col-lg-12'>
                                    <p className="fst-italic">
                                        PHP &amp; Laravel Developer <i className="bi bi-arrow-right" style={{ color: "#ff9600" }}></i>
                                        React JS <i className="bi bi-arrow-right" style={{ color: "#ff9600" }}></i>
                                        React Native <i className="bi bi-arrow-right" style={{ color: "#ff9600" }}></i>
                                        Node Js

                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <i style={{ color: "#fd562a" }} className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+92 316 6539256</span>
                                </div>
                                <div className="col-lg-6">
                                    <i style={{ color: "#fd562a" }} className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Lahore, Punjab Pakistan</span>
                                </div>
                                <div className="col-lg-6">
                                    <i style={{ color: "#fd562a" }} className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>muh.safdarmalik@gmail.com</span>
                                </div>
                                <div className="col-lg-6">
                                    <i style={{ color: "#fd562a" }} className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span>
                                </div>
                                <div className='col-lg-12'>
                                    <p style={{ textAlign: "justify" }}>
                                        I am a passionate Full Stack Developer with over 5+ years of experience in designing and building robust web applications. My expertise spans both front-end and back-end development, allowing me to create seamless user experiences and efficient server-side functionality.
                                    </p>
                                    <p style={{ textAlign: "justify" }}>
                                        I am proficient in a variety of programming languages and frameworks, including PHP and Laravel for back-end development, and React.js and React Native for crafting dynamic user interfaces and mobile applications. My knowledge of Node.js enhances my ability to develop scalable network applications.
                                    </p>
                                    <p style={{ textAlign: "justify" }}>
                                        I have extensive experience working with databases such as MySQL and MongoDB, ensuring efficient data management and retrieval. My collaborative approach and problem-solving skills enable me to work effectively in diverse team environments, delivering high-quality results on time.
                                    </p>
                                    <p style={{ textAlign: "justify" }}>
                                        I am always eager to learn and stay updated with the latest industry trends and technologies, ensuring that I can contribute innovative solutions to projects. My goal is to leverage my skills to build impactful applications that meet user needs and drive business success.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUsSection